<template>
<loader v-bind="{ loading }">
  <div class="columns">
    <div class="column">
      <action-button 
        :working="downloading"
        left-icon="download"
        class="is-rounded is-small is-info" @click="downloadXls">Download XLS Template</action-button>
    </div>
  </div>
  <form>
    <columns>
      <column class="is-5">
        <text-input
          classes="is-rounded is-medium"
          :value="checklist.name"
          @input="updateName"
          :error="$root.errors.name"
          required>
          Name
        </text-input>
      </column>
      <column class="is-2">
        <number-input
          classes="is-rounded is-medium"
          :precision="0"
          :max="100"
          :min="0"
          :value="checklist.pass_percentage"
          @input="updatePassPercentage"
          :error="$root.errors.pass_percentage">
          Pass Percentage
        </number-input>
      </column>
      <column class="is-2">
        <data-selector
          classes="is-rounded is-medium"
          :items="checklist_types"
          value-key="value"
          label-key="label"
          :value="checklist.type"
          @input="updateType"
          :error="$root.errors.type">
          Type
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-rounded is-medium"
          :items="classifications"
          value-key="uuid"
          label-key="name"
          required
          :value="checklist.classification_uuid"
          @input="updateClassification"
          :error="$root.errors.classification_uuid">
          Classification
        </data-selector>
      </column>
      <column class="is-narrow is-flex is-align-items-end">
        <action-button 
          :working="copying"
          @click="copyTemplate"
          left-icon="copy"
          left-icon-type="far"
          class="is-rounded">
          Copy
        </action-button>
      </column>
    </columns>
    <columns>
      <column>
        <text-area 
          classes="is-rounded is-medium"
          @input="updateDescription"
          :value="checklist.description">
          Description
        </text-area>
      </column>
    </columns>

    <segmented-teams />

    <columns v-if="checklist.type === 'equipment'">
      <column>
        <equipment-classification-builder />
      </column>
    </columns>

    <columns>
      <column>
        <submit-button
          class="is-rounded is-medium"
          :working="updating"
          @submit="updateCategory">Save</submit-button>
      </column>
      <column class="is-narrow">
        <div class="buttons has-addons">
          <action-button
            class="is-rounded is-small is-danger"
            :working="deleting"
            :disabled="updating"
            @click="deleteChecklistTemplate" left-icon="trash">Delete</action-button>
        </div>
        
      </column>
    </columns>
  </form>
</loader>  
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { checklistClassification as backend } from '@/api'
import EquipmentClassificationBuilder from './partials/EquipmentClassificationBuilder.vue'
import SegmentedTeams from './partials/TeamSegmentation.vue'
import download from 'downloadjs'
import { common as downloadBackend } from '@/api'

export default {

  components: {
    EquipmentClassificationBuilder,
    SegmentedTeams
  },
  
  data: () => ({
    loading: false,
    updating: false,
    copying: false,
    deleting: false,
    downloading: false,
    classifications: []
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadTeamList')
    await backend.loadList(({data}) => {
      this.classifications = data
    })
    this.loading = false
  },
  
  methods: {
    ...mapMutations('checklist', [
      'updateName',
      'updatePassPercentage',
      'updateDescription',
      'clearChecklist',
      'updateClassification',
      'updateType'
    ]),
    updateCategory() {
      this.updating = true
      this.$store.dispatch('checklist/update').then(() => {
        this.updating = false
        this.$toast.success('Checklist successfully updated')
      }).catch(() => this.updating = false)
    },
    async deleteChecklistTemplate() {

      if(this.checklist.inspections_count) {
        return this.$toast.error('This checklist template cannot be deleted because it has inspections that have been conducted against it.')
      }

      if(await this.$confirm({
        title: 'Delete Checklist Template',
        message: 'Are you sure you want to delete this checklist template?'
      })) {
        this.deleting = true
        this.$store.dispatch('checklist/deleteChecklist').then(() => {
          this.deleting = false
          this.$toast.success('Successfully deleted')
          this.$router.push({
            name: 'checklist-templates'
          })
        }).catch(() => this.deleting = false)
      }
    },
    copyTemplate() {
      this.copying = true
      this.$store.dispatch('checklist/copy').then(async (data) => {
        this.copying = false
        this.$toast.success('Success')
        this.clearChecklist()
        await this.$store.dispatch('checklist/load', data.id)
        this.$router.push({
          name: 'checklist-template-manager',
          params: {
            checklistTemplate: data.id
          }
        })
      }).catch(() => this.copying = false)
    },
    downloadXls() {
      this.downloading = true
      downloadBackend.downloadFile(`/v1/checklist-templates/${this.checklist.uuid}/download-xls`, ({ data }) => {
          download(data, this.checklist.name + '.xlsx', data.type)
          this.downloading = false
      }, () => this.downloading = false)
    }
  },

  computed: {
    ...mapGetters('checklist', [
      'checklist',
      'checklist_types'
    ])
  }

}
</script>